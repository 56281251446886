<template>
  <div class="mb-2 sub-menu">
    <b-button
      :id="menu.routeName"
      :variant="
        $route.meta.navActiveTab == menu.active ? `primary` : `outline-primary`
      "
      v-for="menu in menus"
      :key="`menu-${menu.routeName}`"
      class="mr-1"
      @click="
        $router.push({ name: menu.routeName, params: { id: $route.params.id } })
      "
    >
      {{ $t(menu.label) }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
  data() {
    return {
      menus: [
        {
          routeName: "update-senior",
          active: "update-senior",
          label: "breadcrumb.updateSenior",
        },
        {
          routeName: "list-senior-block-number",
          active: "senior-block-number",
          label: "breadcrumb.blockNumber",
        },
        {
          routeName: "list-senior-allow-number",
          active: "senior-allow-number",
          label: "breadcrumb.allowNumber",
        },
        {
          routeName: "list-senior-sell-out",
          active: "senior-sell-out",
          label: "breadcrumb.sellOut",
        },
        {
          routeName: "list-senior-bet-setting",
          active: "senior-bet-setting",
          label: "breadcrumb.betSetting",
        },
      ],
    };
  },
  mounted() {
    this.scrollToMenu();
  },
  methods: {
    scrollToMenu() {
      setTimeout(() => {
        const element = document.getElementById(this.$route.name);
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 200);
    },
  },
  setup() {},
};
</script>